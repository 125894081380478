import React from "react";
import moment from "moment";
import { Collapse } from "antd";
const { Panel } = Collapse;

const formatDate = (date) =>
  `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;

const ListGroupEmployeesJobOffers = ({ group, year }) => {
  const today = moment();
  const yesterday = moment().subtract(1, "day");
  const startOfMonth = moment().startOf("month");
  const thirtyDaysAgo = moment().subtract(30, "days");
  const sixtyDaysAgo = moment().subtract(60, "days");
  const futureHires = [];
  const futureTerminations = [];
  const newHires = [];
  const currentEmployees = [];
  const recentFormerEmployees = [];
  const formerEmployees = [];
  const benefitIneligible = [];
  const jobOffers = [];
  group?.currentEmployeeMap &&
    Object.values(group.currentEmployeeMap).forEach((employee) => {
      if (moment(employee.date) >= today) {
        futureHires.push(employee);
      } else if (moment(employee.date) >= thirtyDaysAgo) {
        currentEmployees.push(employee);
        newHires.push(employee);
      } else {
        currentEmployees.push(employee);
      }
    });
  group?.formerEmployeeMap &&
    Object.values(group.formerEmployeeMap).forEach((employee) => {
      if (moment(employee.date) >= today) {
        employee.futureTermination = true;
        currentEmployees.push(employee);
        futureTerminations.push(employee);
      } else if (moment(employee.date) >= sixtyDaysAgo) {
        recentFormerEmployees.push(employee);
      } else {
        formerEmployees.push(employee);
      }
    });
  group?.benefitIneligible &&
    Object.values(group.benefitIneligible).forEach((employee) => {
      benefitIneligible.push(employee);
    });
  group?.jobOfferMap &&
    Object.values(group.jobOfferMap).forEach((employee) => {
      jobOffers.push(employee);
    });

  futureHires.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  futureTerminations.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  newHires.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  currentEmployees.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  recentFormerEmployees.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  formerEmployees.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  benefitIneligible.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  jobOffers.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });

  let currentEmployeesYesterday = 0;
  let currentEmployeesStartOfMonth = 0;
  let newHiresScheduled = 0;
  let terminationsScheduled = 0;

  if (group?.currentEmployeeMap) {
    Object.keys(group.currentEmployeeMap).forEach((email) => {
      const date = moment(group.currentEmployeeMap[email].date);
      if (date <= yesterday) currentEmployeesYesterday++;
      if (date <= startOfMonth) currentEmployeesStartOfMonth++;
      if (date > startOfMonth) newHiresScheduled++;
    });
  }

  if (group?.formerEmployeeMap) {
    Object.keys(group.formerEmployeeMap).forEach((email) => {
      const date = moment(group.formerEmployeeMap[email].date);
      if (date > startOfMonth) terminationsScheduled++;
      if (date > yesterday) currentEmployeesYesterday++;
      if (date > startOfMonth) currentEmployeesStartOfMonth++;
    });
  }

  return (
    <div>
      {group && year && (
        <div>
          <ol>
            <li>
              Current Employees as of {yesterday.format("MM/DD/YYYY")} ({currentEmployeesYesterday})
            </li>
            <li>
              Employees as of {startOfMonth.format("MM/DD/YYYY")} ({currentEmployeesStartOfMonth})
            </li>
            <li>
              New Hires Scheduled for {startOfMonth.format("MM/DD/YYYY")} or Later ({newHiresScheduled})
            </li>
            <li>
              Terminations Scheduled for {startOfMonth.format("MM/DD/YYYY")} or Later ({terminationsScheduled})
            </li>
            <li>Current Outstanding Job Offers ({group?.jobOfferMap ? Object.keys(group.jobOfferMap).length : 0})</li>
          </ol>
        </div>
      )}
      <Collapse ghost>
        {futureHires.length ? (
          <Panel header={`Future Hires (${futureHires.length.toLocaleString()})`} key="1">
            {futureHires.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {newHires.length ? (
          <Panel header={`New Hires from Past 30 Days (${newHires.length.toLocaleString()})`} key="2">
            {newHires.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {currentEmployees.length ? (
          <Panel header={`Current Employees (${currentEmployees.length.toLocaleString()})`} key="3">
            {currentEmployees.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} -{" "}
                {employee.futureTermination
                  ? employee.hireDate
                    ? `${employee.hireDate} - ${employee.date} (Future Termination)`
                    : `${employee.date} (Future Termination)`
                  : employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {futureTerminations.length ? (
          <Panel header={`Future Terminations (${futureTerminations.length.toLocaleString()})`} key="4">
            {futureTerminations.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {recentFormerEmployees.length ? (
          <Panel
            header={`Recent Terminations from Past 60 Days (${recentFormerEmployees.length.toLocaleString()})`}
            key="5"
          >
            {recentFormerEmployees.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {formerEmployees.length ? (
          <Panel header={`Terminations More Than 60 Days Ago (${formerEmployees.length.toLocaleString()})`} key="6">
            {formerEmployees.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {benefitIneligible.length ? (
          <Panel header={`Benefit Ineligible (${benefitIneligible.length.toLocaleString()})`} key="7">
            {benefitIneligible.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {employee.date}
              </div>
            ))}
          </Panel>
        ) : null}
        {jobOffers.length ? (
          <Panel header={`Job Offers (${jobOffers.length.toLocaleString()})`} key="8">
            {jobOffers.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.roles?.[year]} - {formatDate(new Date(employee.createdAt))}
              </div>
            ))}
          </Panel>
        ) : null}
      </Collapse>
      {group && !group?.currentEmployeeMap && !group?.formerEmployeeMap && !group?.jobOfferMap ? (
        <div>No emails found</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListGroupEmployeesJobOffers;
