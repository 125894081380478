import React, { useState } from "react";
import { dataMigration } from "services/api/groups.api";
import { Card, message, Button } from "antd";
import { getToken } from "services/utils";

// this as a quick panel developed to run some data migrations for groups.
// in the future, we'll need something similar for migrating employees from groups to a separate collection
// after that, we can delete this component

const GroupsDataMigrationsPanel = () => {
  const [results, setResults] = useState(null);
  const handleDataMigration = async (save = false) => {
    const token = await getToken();
    const response = await dataMigration({ save, token });
    if (response.status === 200) {
      setResults(response.data);
    } else {
      message.error("Internal Server Error");
    }
  };
  return (
    <Card title="Data Migrations Section">
      <Button
        type="primary"
        onClick={() => handleDataMigration(false)}
        style={{
          marginRight: "10px",
        }}
      >
        Run QU Data Migration
      </Button>
      {results && (
        <Button type="primary" onClick={() => handleDataMigration(true)}>
          Confirm and Save QU Data Migration
        </Button>
      )}
      {results && (
        <>
          {results.userStoredEmailsChanges.length ? (
            <div>
              <h3>User Stored Emails</h3>
              {results.userStoredEmailsChanges.map((user, idx) => (
                <div key={idx}>
                  {user.email} - {user.date}
                </div>
              ))}
            </div>
          ) : null}
          {results.benefitPlanNameChanges.length ? (
            <div>
              <h3>Benefit Plan Name</h3>
              {results.benefitPlanNameChanges.map((employee, idx) => (
                <div key={idx}>
                  {employee.email} - got converted from {employee.benefitPlanName} to {employee.newBenefitPlanName}
                </div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default GroupsDataMigrationsPanel;
